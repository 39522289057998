/**
 * LandingPage
*/
import React from 'react';
import get from 'lodash/get';
import classnames from 'classnames';
import { Link, graphql } from 'gatsby';
import { isOffline } from '@utils';
import BackgroundImage from 'gatsby-background-image';
import { isLoggedIn } from '../../services/auth';
import { Button } from './components';
import Logo from '@assets/svgs/Logo';
import css from './Landingpage.module.css';

const LandingPage = ({ data, pageContext }) => {
  const page = get(data, 'wordpressPage', {}) || {};
  const acf = get(page, 'acf', {}) || {};
  const image = get(acf, 'landingpage_background_image.localFile.childImageSharp.fluid', null);
  const title = get(acf, 'landingpage_title', null);
  const eyebrow = get(acf, 'landingpage_eyebrow', null);
  const buttons = get(acf, 'landingpage_buttons', null);
  const previousCP = get(acf, 'landingpage_previous_collection_premiere', null);

  const content = (
    <div className={css.wrapper}>
      <Logo className={css.logo} />
      <div className={css.content}>
        <span className={css.eyebrow} dangerouslySetInnerHTML={{ __html: eyebrow }} />
        <h1 className={css.title} dangerouslySetInnerHTML={{ __html: title }} />
        {buttons && (
           <div className={css.buttons}>
             {
               buttons.map(({ label, page }) => (
                 <Button 
                  className={css.buttons__button}
                  element={Link}
                  to={!isLoggedIn() && !isOffline ? '/login' : page}
                  key={page}
                >
                   {label}
                 </Button>
               ))
             }
           </div>
        )}
          <div className={css.belowButtons}>
            {previousCP && (
              <a rel="noopener noreferrer" href={previousCP.url} target="_blank" className={css.belowButtons__link}>
                <svg className={css.belowButtons__link__icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" data-test-icon-element="true" focusable="false"><path d="M28.2 14.5h-20l6.2-6.2c.8-.8.8-2 0-2.8s-2-.8-2.8 0l-9.4 9.6c-.8.8-.8 2 0 2.8l9.6 9.6c.8.8 2 .8 2.8 0s.8-2 0-2.8l-6.2-6.2h20c1.2 0 2-.8 2-2s-1-2-2.2-2z"></path></svg>
                {previousCP.label}
              </a>
            )}
            { !isOffline && (
              <Link to="/download" className={css.belowButtons__link}>
                Download offline app
                <svg className={classnames(css.belowButtons__link__icon, css.inverted)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" data-test-icon-element="true" focusable="false"><path d="M28.2 14.5h-20l6.2-6.2c.8-.8.8-2 0-2.8s-2-.8-2.8 0l-9.4 9.6c-.8.8-.8 2 0 2.8l9.6 9.6c.8.8 2 .8 2.8 0s.8-2 0-2.8l-6.2-6.2h20c1.2 0 2-.8 2-2s-1-2-2.2-2z"></path></svg>
              </Link>
            )}
          </div>
      </div>
    </div>
  );

  if (image) {
    return (
      <BackgroundImage fluid={image} critical={isOffline} fadeIn="soft">
        {content}
      </BackgroundImage>      
    );
  }

  return content;
}

export default LandingPage

export const landingPageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      acf {
        landingpage_eyebrow
        landingpage_title
        landingpage_buttons {
          label
          page
        }
        landingpage_previous_collection_premiere {
          label
          url
        }
        landingpage_background_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, srcSetBreakpoints: [900]) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      } 
    }
  }
`
